import React, {
    useState,
    useRef,
    useEffect,
    RefObject,
    MouseEventHandler,
} from "react";

//Images
import IconDropdown from "images/icon-dropdown.svg";
import IconMenu from "images/icon-hamburger-menu.svg";
import Close from "images/ui-plus-close.svg";
import MenuArrow from "images/ui-menu-arrow.svg";

import styles from "styles/components/MenuToggle.module.scss";
import Link from "next/link";
import topic from "pages/topics/[slug]";
import { IfaceMenuApi } from "helpers/interfaces";
import { fetchMenu, slugify } from "helpers/functions";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { useRouter } from "next/router";

// State
import {
    topicListState,
    hideMenuTooltipState
} from "state/atoms";

// Interfaces
import {
    IfaceAllNewestResponse,
} from "helpers/interfaces";

type Props = {
    menu: IfaceMenuApi | any;
    fgdMenu?: IfaceMenuApi | any;
    toggleMenu: () => void;
    showMenu: boolean;
    isFGDUser?: boolean;
    // topics: [
    //     {

    //     }
    // ]
};

const MenuItem = (props: any) => {
    const isFGDMenu: boolean = props.isFGDMenu || false;
    const parent: any = props.parent || null;
    const [menuData, setMenuData] = useState<any>(props.itemData ? { ...props.itemData } : null);
    const [showSubMenu, setShowSubMenu] = useState(false);

    let ref = useRef<HTMLLIElement>(null);

    useEffect(() => {
        const handler = (event: any) => {
            if (
                showSubMenu &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setShowSubMenu(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [showSubMenu]);

    const toggleSubMenu = () => {
        showSubMenu
            ? setShowSubMenu(false)
            : setShowSubMenu(true);
    };

    const topics = useRecoilValue(topicListState);
    let sortedTopics: any = topics ? [...topics.res.data] : null;

    function compare(a: any, b: any) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    if (sortedTopics) {
        sortedTopics.sort(compare);
    }

    const [subMenu, setSubMenu] = useState<any>(null);
    const getSubMenu = async () => {
        let menuID: any = null;
        if (props.itemData.sub_menu_id) {
            menuID = props.itemData.sub_menu_id;
        } else if (props.itemData.sub_menu && props.itemData.sub_menu.id) {
            menuID = props.itemData.sub_menu.id;
        }
        if (menuID) {
            const menuRes: IfaceMenuApi = await fetchMenu(menuID);
            if (!menuRes.error && menuRes.res.data && menuRes.res.data.length > 0) {
                let updatedMenuData: any = { ...menuData };
                updatedMenuData.children = menuRes.res.data;
                setMenuData(updatedMenuData);
            }
        }
    }

    useEffect(() => {
        if (props.itemData && isFGDMenu && parent && (parent === 'Downloads' || parent === 'Guidelines')) {
            getSubMenu();
        }
    }, [showSubMenu]);

    return (
        <>
            {menuData.children && menuData.children.length > 0 ? (
                <li className={styles.parentItem} ref={ref}>
                    <button onClick={toggleSubMenu} aria-haspopup="menu" aria-expanded={showSubMenu} className={slugify(menuData.name)}>{menuData.name}</button>
                    {showSubMenu && (
                        <div className={`${styles.panel} ${styles.subPanel} ${showSubMenu ? styles.show : ''}`}>
                            <div className={styles.panelInner}>
                                <div className={styles.headerRow}>
                                    <h3>{isFGDMenu ? menuData.name : 'Explore All'}</h3>
                                </div>
                                <button className={styles.closeBtn} onClick={() => {
                                    setShowSubMenu(false);
                                }}>
                                    <span>Close All</span>
                                    <Close />
                                </button>
                                <ul>
                                    {menuData.children.map((subItem: any, index: any) => {
                                        if (subItem.enabled) {
                                            return (
                                                <MenuItem
                                                    key={`sub-menu-item-${index}`}
                                                    itemData={subItem}
                                                    prevToggle={setShowSubMenu}
                                                    isFGDMenu={isFGDMenu}
                                                    parent={menuData.name}
                                                />
                                            )
                                        } else {
                                            return <></>
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                </li>
            ) : (
                <li>
                    {menuData.url ? (
                        <>
                            {menuData.target === '_blank' ? (
                                <a href={`${menuData.url ? menuData.url : '#'}`} target={menuData.target} className={`${slugify(menuData.name)}`}>{menuData.name}</a>
                            ) : (
                                <a className={`${slugify(menuData.name)}`} data-url={slugify(menuData.name)} href={`${menuData.url ? menuData.url : '#'}`}>{menuData.name}</a>
                            )}
                        </>
                    ) : (
                        <span>{menuData.name}</span>
                    )}
                </li >
            )}
        </>
    )
}

const MenuToggle = ({
    // topics = defaultTopics,
    showMenu,
    toggleMenu,
    menu,
    fgdMenu,
    isFGDUser = false
}: Props) => {
    // Close menu on page change
    const router = useRouter();
    useEffect(() => {
        if (showMenu) {
            toggleMenu();
        }
    }, [router.asPath]);

    const hideTooltip = useRecoilValue(hideMenuTooltipState);

    //Topics toggle
    const [showTopics, setShowTopics] = useState(false);
    const toggleTopics = () => {
        showTopics
            ? setShowTopics(false)
            : setShowTopics(true);
    };

    const [showGuidelines, setShowGuidelines] = useState(false);
    const toggleGuidelines = () => {
        showTopics
            ? setShowGuidelines(false)
            : setShowGuidelines(true);
    };

    const [showMcFlurry, setShowMcFlurry] = useState(false);
    const toggleMcFlurry = () => {
        showTopics
            ? setShowGuidelines(false)
            : setShowGuidelines(true);
    };

    /**
     * Hook that alerts clicks outside of the passed ref
     * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
     */
    // TODO: clean up type 'any' here
    function useOutsideAlerter(ref: HTMLElement | any) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    showMenu === true
                ) {
                    toggleMenu();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [showMenu]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    // Set variable on component mount to see if we should have
    // two columns or just one
    const wideThreshold = 6;
    let isExtraWide = false;

    const topics: any = {
        error: true,
        res: null,
    };

    return (
        <div className={`${styles.container} ${styles.menuToggleContainer}`} ref={wrapperRef}>
            <div className={styles.toggleContainer}>
                <button
                    id={'nav-menu-button'}
                    className={`menu-toggle-btn ${styles.desktopMenuDropdown} ${showMenu ? `${styles.closeBtn}` : ''}`}
                    onClick={toggleMenu}
                    aria-label={
                        showMenu
                            ? "Close Topics Dropdown"
                            : "Open Topics Dropdown"
                    }>{showMenu ? <Close /> : <IconMenu />}{" "}Explore</button>
                {!hideTooltip && (
                    <div className={styles.menuTooltip}>
                        <div className={styles.tooltipInner}>
                            <p>Use the Explore menu to browse articles, campaigns, creative briefs, and more</p>
                        </div>
                    </div>
                )}
            </div>
            {showMenu && (
                <div id={'nav-menu-container'} className={`${styles.dropdown} ${isExtraWide ? styles.extraWide : ""
                    }`}>
                    <div className={styles.dropdownInner}>
                        <div className={styles.dropdownBackground} onClick={toggleMenu}></div>
                        <div className={`${styles.panel} ${styles.parentLinks} ${showMenu ? styles.show : ''}`}>
                            <div className={styles.panelInner}>
                                <button className={styles.closeBtn} onClick={toggleMenu}>
                                    <Close />
                                    <span>Close Menu</span>
                                </button>
                                {!isFGDUser && menu.res && menu.res.data && menu.res.data.length > 0 && menu.res.data.map((parentItem: any, index: any) => {
                                    if (parentItem.enabled) {
                                        return (
                                            <div className={styles.menuParentItem} key={`menu-parent-item-${index}`}>
                                                <h2>{parentItem.name}</h2>
                                                {parentItem.children && parentItem.children.length > 0 && (
                                                    <ul>
                                                        {parentItem.children.map((childItem: any, index: any) => {
                                                            if (childItem.enabled) {
                                                                return (
                                                                    <MenuItem
                                                                        key={`menu-item-${index}`}
                                                                        itemData={childItem}
                                                                    />
                                                                )
                                                            } else {
                                                                return <></>
                                                            }
                                                        })}
                                                    </ul>
                                                )}
                                            </div>
                                        )
                                    }
                                })}
                                {fgdMenu && fgdMenu.res && fgdMenu.res.data && fgdMenu.res.data.length > 0 && (
                                    <div className={styles.menuParentItem} key={`menu-parent-item-fgd`}>
                                        <h2>Feel-Good Design Hub</h2>
                                        {fgdMenu.res.data.length > 0 && (
                                            <ul>
                                                {fgdMenu.res.data.map((childItem: any, index: any) => {
                                                    if (childItem.enabled) {
                                                        return (
                                                            <MenuItem
                                                                key={`fgd-menu-item-${index}`}
                                                                itemData={childItem}
                                                                isFGDMenu={true}
                                                            />
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MenuToggle;
